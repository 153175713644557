<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Delivery
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Reason
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn small class="mx-4" v-on:click="createJobType">
                  <span class="font-size-16 font-weight-600"
                    >Create Reason</span
                  >
                </v-btn>
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateJobSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr v-for="(type, index) in reasonList" :key="index">
                    <td>
                      <v-text-field
                        v-model.trim="type.title"
                        dense
                        filled
                        label="Reason"
                        solo
                        flat
                        class="px-4 py-2"
                        hide-details
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                      ></v-text-field>
                    </td>
                    <td width="50">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!type.id"
                            v-on:click="deleteJobType(index)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                          <v-icon
                            v-if="type.id"
                            v-on:click="deleteJobTypeById(type.id)"
                            color="deep-orange"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Click here to delete</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="4" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Mobile Application
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="8" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateApplicationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <table class="width-100 mt-4">
                <tbody>
                  <tr>
                    <v-switch
                      v-model="allow_accept_reject"
                      class="mt-0"
                      inset
                      label="Allow Accept / Reject Button"
                      color="green"
                    ></v-switch>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, POST, DELETE, PATCH } from "@/core/services/store/request.module";

export default {
  name: "profile-job-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        client_remark: null,
        next_number: null,
        number_length: null,
        buffer_time: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        job_type: [],
      },
      allow_accept_reject: 1,
      reasonList: [
        {
          id: null,
          title: null,
          value: null,
        },
      ],
    };
  },
  components: {},
  methods: {
    updateApplicationSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/delivery-service-app",
          data: {
            allow_accept_reject: this.allow_accept_reject ? 1 : 0,
          },
        })
        .then(() => {
          this.getDeliverySetting();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    createJobType() {
      this.reasonList.push({
        id: null,
        title: null,
        value: null,
      });
    },
    deleteJobType(index) {
      if (this.reasonList.length > 1) {
        this.reasonList.splice(index, 1);
      }
    },
    getDeliverySetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/delivery" })
        .then(({ data }) => {
          this.allow_accept_reject =
            data.allow_accept_reject && data.allow_accept_reject == 1
              ? true
              : false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getReasonList() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "reason-list" })
        .then(({ data }) => {
          _this.reasonList = data.data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    deleteJobTypeById(id) {
      this.pageLoading = true;
      this.$store
        .dispatch(DELETE, {
          url: "reason-delete",
          data: {
            id: id,
          },
        })
        .then(() => {
          this.getReasonList();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    updateJobSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "create-reason",
          data: {
            reasonList: _this.reasonList,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getReasonList();
    this.getDeliverySetting();
  },
};
</script>
