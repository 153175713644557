<template>
  <v-container fluid class="col-lg-10" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Terms & Conditions List
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat class="custom-grey-border remove-border-radius mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-row>
          <v-col md="6" class="py-0 my-auto">
            <h3
              class="font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Manage Terms & Conditions
            </h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <v-row>
          <v-col md12>
            <table width="100%">
              <thead>
                <tr>
                  <th align="left" class="text-center py-2" width="3%">#</th>
                  <th align="left" width="15%" class="py-2">Type</th>
                  <th align="left" width="10%" class="py-2">Module</th>
                  <th width="20%" class="py-2">Label</th>
                  <th class="py-2">Description</th>
                  <th class="py-2" width="10%"></th>
                </tr>
              </thead>
              <tbody v-if="note_listing.length">
                <tr
                  v-for="(row, index) in note_listing"
                  :key="index"
                  class="border-top"
                >
                  <td align="left" class="py-2">{{ index + 1 }}</td>
                  <td align="left" class="py-2">
                    <div class="d-flex">
                      <v-chip
                        label
                        outlined
                        text-color=""
                        color="blue"
                        class="white--text text-uppercase"
                        :class="`btx-autocomplete-type-${row.type}`"
                        >{{ row.type }}
                        <template
                          v-if="row.type == 'internal' || row.type == 'client'"
                        >
                          Notes</template
                        >
                      </v-chip>
                      <v-spacer></v-spacer>
                      <template v-if="row.primary">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="green darken-1"
                              class="ml-2"
                              >mdi-record</v-icon
                            >
                          </template>
                          <span>Marked as Default</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </td>
                  <td class="py-2" align="left">
                    <v-chip
                      outlined
                      text-color=""
                      label
                      :color="getModuleColor(row.module)"
                      class="white--text text-uppercase"
                      :class="`btx-autocomplete-module-${row.module}`"
                      >{{ row.module }}</v-chip
                    >
                  </td>
                  <td class="py-2">{{ row.label }}</td>
                  <td class="py-2">
                    <template v-if="row.listing_desc">
                      <span v-html="row.listing_desc"></span>
                    </template>
                    <em v-else class="text-muted"> no description</em>
                  </td>
                  <td align="left" class="py-2">
                    <v-btn
                      small
                      icon
                      color="cyan"
                      v-on:click="edit_note_dialog(row)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      class="ml-4"
                      small
                      icon
                      color="red"
                      :loading="deleteLoading"
                      :disabled="deleteLoading"
                      v-on:click="delete_row(row)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
              <tfoot v-else>
                <tr class="border-top border-bottom">
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... Nothing Found.
                    </p>
                  </td>
                </tr>
              </tfoot>
            </table>
            <v-btn
              small
              v-on:click="open_note_dialog()"
              class="mt-4 text-white"
              color="cyan"
              :disabled="pageLoading"
              :loading="pageLoading"
            >
              <span class="font-size-16 font-weight-600"
                >Add Terms & Conditions</span
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <note-dialog :common-dialog="note_dialog" :dialog-width="dialogWidth">
      <template v-slot:title> Add Autocomplete </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="noteForm"
            v-model="noteForm"
            lazy-validation
            v-on:submit.stop.prevent="update_or_create_note()"
          >
            <table width="100%">
              <tr>
                <th valign="middle" width="15%" class="py-2">Module</th>
                <td class="py-2">
                  <v-radio-group
                    :disabled="pageLoading || formLoading"
                    v-model="note.module"
                    row
                    hide-details
                    class="mt-0"
                  >
                    <v-radio
                      color="green"
                      label="Rental"
                      value="rental"
                    ></v-radio>
                    <v-radio color="blue" label="Sale" value="sale"></v-radio>
                    <v-radio
                      color="orange"
                      label="Visit"
                      value="visit"
                    ></v-radio>
                    <v-radio
                      color="red darken-4"
                      label="Delivery"
                      value="delivery"
                    ></v-radio>
                    <!-- <v-radio
                      color="orange"
                      label="Payment"
                      value="payment"
                    ></v-radio> -->
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="middle" width="15%" class="py-2">Type</th>
                <td class="py-2">
                  <v-radio-group
                    :disabled="pageLoading || formLoading"
                    v-model="note.type"
                    row
                    mandatory
                    hide-details
                    class="mt-0"
                  >
                    <template>
                      <v-radio
                        color="cyan"
                        label="Terms &amp; Conditions"
                        value="term_condition"
                      ></v-radio>
                      <!-- <v-radio
                        color="cyan"
                        label="Internal Notes"
                        value="internal"
                      ></v-radio>
                      <v-radio
                        color="cyan"
                        label="Client Notes (visible on PDF)"
                        value="client"
                      ></v-radio> -->
                    </template>
                    <!-- <template v-if="note.module == 'invoice'">
                      <v-radio
                        color="cyan"
                        label="Payment Terms"
                        value="payment_term"
                      ></v-radio>
                    </template> -->
                  </v-radio-group>
                </td>
              </tr>
              <tr>
                <th valign="middle" class="py-2 required">
                  <label for="label" class="required"> Label </label>
                </th>
                <td class="py-2">
                  <v-text-field
                    placeholder="Label"
                    :class="!note.label ? 'error--text' : ''"
                    dense
                    solo
                    flat
                    color="cyan"
                    v-model="note.label"
                    hide-details
                    :disabled="pageLoading || formLoading"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Description</th>
                <td class="py-2">
                  <tiny-mce
                    :disabled="formLoading"
                    v-model="note.description"
                  />
                </td>
              </tr>
              <tr>
                <th valign="top" class="pt-4">Default?</th>
                <td class="py-2">
                  <v-radio-group
                    hide-details
                    class="mx-0 my-0 py-0 px-0"
                    v-model="note.primary"
                    row
                  >
                    <v-radio color="cyan" label="Yes" :value="1"></v-radio>
                    <v-radio color="cyan" label="No" :value="0"></v-radio>
                  </v-radio-group>
                </td>
              </tr>
            </table>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          v-on:click="note_dialog = false"
          >Cancel</v-btn
        >
        <v-btn
          class="custom-bold-button"
          :disabled="formLoading"
          :loading="formLoading"
          v-on:click="update_or_create_note()"
          color="cyan white--text"
          >Save</v-btn
        >
      </template>
    </note-dialog>
  </v-container>
</template>

<script>
import { GET, PATCH, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      formLoading: false,
      deleteLoading: false,
      noteForm: true,
      note: {
        id: null,
        module: "rental",
        type: "term_condition",
        label: null,
        primary: 0,
        description: null,
      },
      note_dialog: false,
      note_listing: [],
    };
  },
  methods: {
    open_note_dialog() {
      this.note = {
        id: null,
        module: "rental",
        type: "term_condition",
        label: null,
        primary: 0,
        description: null,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    getModuleColor(module) {
      if (module == "payment") {
        return "orange";
      }
      if (module == "invoice") {
        return "red";
      }
      if (module == "rental") {
        return "green";
      }
      if (module == "sale" || module == "sell") {
        return "blue";
      }
      if (module == "visit") {
        return "orange";
      }
      if (module == "delivery") {
        return "red darken-4";
      }
    },
    edit_note_dialog(row) {
      this.note = {
        id: row.id,
        module: row.module,
        type: row.type,
        label: row.label,
        primary: row.primary,
        description: row.description,
      };
      this.$nextTick(function () {
        this.note_dialog = true;
      });
    },
    delete_row(row) {
      let _this = this;
      this.deleteLoading = true;
      let id = row.id;
      setTimeout(function () {
        _this.$store
          .dispatch(DELETE, { url: "setting/autocompletes/" + id })
          .then(() => {
            _this.deleteLoading = false;
            // _this.note_listing = data;
            // _this.note_dialog = false;
            _this.getAllNotes();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
            _this.deleteLoading = false;
          });
      }, 500);
    },
    update_or_create_note() {
      let _this = this;
      /* 
      const vStatus = _this.$refs.noteForm.validate();

      if (!vStatus) {
        return false;
      } */

      if (!_this.note?.label) {
        ErrorEventBus.$emit("update:error", "Label is required");
        return false;
      }

      _this.formLoading = true;

      setTimeout(function () {
        _this.$store
          .dispatch(PATCH, { url: "setting/autocompletes", data: _this.note })
          .then(({ data }) => {
            _this.note_listing = data;
            _this.note_dialog = false;
            _this.getAllNotes();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }, 500);
    },
    getAllNotes() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/autocompletes" })
        .then(({ data }) => {
          _this.note_listing = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    "tiny-mce": TinyMCE,
    "note-dialog": Dialog,
  },
  mounted() {
    this.getAllNotes();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
